<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible && tableClicked == tableId }"
      :style="{ visibility: visible && tableClicked == tableId ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasSendNotificationLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="send" /></span> Send notification
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body offcanvas-body--view">
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Template <a
            href=""
            target="_blank"
          ><i data-feather="eye" /></a></label>
          <select
            id=""
            name=""
            class="form-select select2"
          >
            <option value="">
              Basic template
            </option>
          </select>
        </div>
        <hr>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >To:</label>
          <v-select
            v-model="usersSelected"
            label="name"
            multiple
            :options="users"
            :get-option-key="option => option.id"
            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
          />
        </div>
        <hr>
        <div class="mb-2">
          <label
            for=""
            class="form-label"
          >Subject</label>
          <input
            v-model="subject"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Message</label>
          <quill-editor v-model="message" />
        </div>

      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-success mb-1 d-grid"
          @click="sendNotification"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible && tableClicked == tableId }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    tableId: {
      type: Number,
      required: false,
      default: null,
    },
    usersSelected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      template: {},
      subject: '',
      message: '',
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/sendNotification',
      tableClicked: 'modals/tableClicked',
      users: 'users/fakeUsers',
    }),
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleSendNotification', false)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async sendNotification() {
      const data = {
        users: this.usersSelected,
        title: this.subject,
        message: this.message,
        type: 'notification',
      }

      await this.$store.dispatch('alerts/store', data)
      this.$toastr.success('', 'Alert send successfully')
      this.closeOffcanva()
    },
  },
}
</script>
